export const environment = {
  environmentName: 'staging',
  enableProdMode: true,
  devMode: false,
  localLogs: false,
  topLevelDomain: '.1life.com',
  apiServer: 'https://staging.1life.com',
  onelifeServer: 'https://staging.1life.com',
  adminServer: 'https://staging-admin.1life.com',
  chartServer: 'https://staging-ui.1life.com',
  oauth2: {
    providerUrl: 'https://staging.1life.com',
    clientId: 'vVNPHVIM1txSNGgjl3ECDWya4dRcF3tcjH1fytwMwb4',
  },
  firebase: {
    useEmulator: false,
    options: {
      apiKey: 'AIzaSyCl0yk6n6wQrswjpqB-Yae3NyD9kq12lJk',
      authDomain: 'one-medical-staging.firebaseapp.com',
      databaseURL: 'https://one-medical-staging.firebaseio.com',
      projectId: 'one-medical-staging',
      storageBucket: 'one-medical-staging.appspot.com',
      messagingSenderId: '758134600070',
      appId: '1:758134600070:web:07a9ad408601b6eaa03c07',
      measurementId: 'G-HVGJ9W806L',
    },
    settings: {},
  },
  openTok: {
    apiKey: '45695472',
  },
  sentry: {
    enabled: true,
  },
  zoom: {
    apiKey: 'SspUrKQ0TQutl1NgWiEKRQ',
    meetingBaseUrl: 'https://zoom.us/j',
  },
  launchDarklyClientId: '600b3ce23cadb30a6ffcc6ac',
};
